<template>
  <div class="auth-wrapper auth-v1 px-2">
    <div class="auth-inner py-2">

      <!-- Forgot Password v1 -->
      <b-card class="mb-0">
        <b-link class="brand-logo">
          <!-- logo -->
          <vuexy-logo />

          <h2 class="brand-text text-primary ml-1">
            <img
              src="@/assets/images/logo/logo-mercadohit.svg"
              width="150"
            >
          </h2>
        </b-link>

        <b-card-title class="mb-1">
          Esqueceu a senha? 🔒
        </b-card-title>
        <b-card-text class="mb-2">
          Digite seu e-mail e nós enviaremos instruções para redefinir sua senha. Relaxa, a gente te ajuda a recuperar o acesso à sua conta, não se preocupe!
        </b-card-text>

        <!-- form -->
        <validation-observer ref="simpleRules">
          <b-form
            class="auth-forgot-password-form mt-2"
            @submit.prevent="validationForm"
          >
            <!-- email -->
            <b-form-group
              label="Email"
              label-for="forgot-password-email"
            >
              <validation-provider
                #default="{ errors }"
                name="Email"
                rules="required|email"
              >
                <b-form-input
                  id="forgot-password-email"
                  v-model="usuarioEmail"
                  :state="errors.length > 0 ? false:null"
                  name="forgot-password-email"
                  placeholder="email@exemplo.com"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- submit button -->
            <b-button
              variant="primary"
              block
              type="submit"
            >
              Enviar link de recuperação
            </b-button>
          </b-form>
        </validation-observer>

        <b-card-text class="text-center mt-2">
          <b-link :to="{name:'autenticacao-login'}">
            <feather-icon icon="ChevronLeftIcon" /> Voltar para o login
          </b-link>
        </b-card-text>

      </b-card>
    <!-- /Forgot Password v1 -->
    </div>

    <!-- basic modal -->
    <b-modal
      id="confirmacaoCarregando"
      ref="confirmacaoCarregando"
      centered
      title="Recuperação de Senha"
      hide-footer
      no-close-on-esc
      no-close-on-backdrop
      hide-header-close
    >
      <b-card-text class="text-center p-2">
        <b-spinner
          class="mt-2"
          variant="primary"
        /><br>
        <strong>Aguarde um instante!</strong><br> Estamos enviando um e-mail com o link para criar sua nova senha. Isso não vai demorar muito, não saia daqui por enquanto!
      </b-card-text>
    </b-modal>

  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import {
  BCard, BLink, BCardText, BCardTitle, BFormGroup, BFormInput, BForm, BButton, BSpinner,
} from 'bootstrap-vue'
import { required, email } from '@validations'

import useJwt from '@/auth/jwt/useJwt'


export default {
  components: {
    VuexyLogo,
    BCard,
    BLink,
    BCardText,
    BCardTitle,
    BFormGroup,
    BFormInput,
    BButton,
    BForm,
    BSpinner,
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      usuarioEmail: '',
      // validation
      required,
      email,
    }
  },
  methods: {
    validationForm() {
      this.$refs.simpleRules.validate().then(success => {

        if (success) {

          this.respostaApi = true

          // MODAL - CONFIRMAÇÃO CARREGANDO - ABRIR
          this.$refs.confirmacaoCarregando.show()

          useJwt.usuarioEsqueciSenha({
            email: this.usuarioEmail,
          })
            .then(response => {

              const iconHtml = `<svg width="30" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><!--! Font Awesome Pro 6.4.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M64 112c-8.8 0-16 7.2-16 16v22.1L220.5 291.7c20.7 17 50.4 17 71.1 0L464 150.1V128c0-8.8-7.2-16-16-16H64zM48 212.2V384c0 8.8 7.2 16 16 16H448c8.8 0 16-7.2 16-16V212.2L322 328.8c-38.4 31.5-93.7 31.5-132 0L48 212.2zM0 128C0 92.7 28.7 64 64 64H448c35.3 0 64 28.7 64 64V384c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V128z"/></svg>`;
              
              this.$swal({
                title: 'Enviamos um email para você!',
                html: 'Para criar uma nova senha, acesse o seu email.<br> Verifique a caixa de entrada ou lixo eletrônico!',
                
                iconHtml: iconHtml,
                customClass: {
                  icon: 'iconEmail',
                  confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,
              })
             
            })
            .catch(error => {
              if (error.response) {

                // RECEBER ERRO VINDO DA API
                const erro = error.response.data.message

                this.$swal({
                  title: 'Ops! Aconteceu um erro.',
                  text: erro,
                  icon: 'error',
                  customClass: {
                    confirmButton: 'btn btn-primary',
                  },
                  buttonsStyling: false,
                })
              }
            })
            .finally(() => {
              this.respostaApi = false

              // MODAL - CONFIRMAÇÃO CARREGANDO - ABRIR
              this.$refs.confirmacaoCarregando.hide()

            })

        }
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';

.iconEmail {
  border: .25em solid rgba(165,220,134,.3) !important;
}

.iconEmail svg {
  fill: #a5dc86;
}

</style>

